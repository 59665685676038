import { useRouter } from 'next/router';
import { TestimonialCard } from '../TestemonialCard';
import { UpperHeadingAndTitle } from '../UpperHeadingAndTitle';
import { translations } from './translations';

interface TestemonialsProps {
  testimonials: {
    name: string;
    role: string;
    content: string;
    avatar: string;
  }[];
}

export function Testemonials({ testimonials }: TestemonialsProps) {
  const router = useRouter();
  const t = translations(router.locale);

  return (
    <div className=" w-full flex flex-col py-12 items-center justify-center max-w-7xl px-4 gap-20">
      <div className="flex flex-col w-full gap-4 max-w-3xl items-center">
        <UpperHeadingAndTitle upperHeading={t.upperHeading} title={t.title} />

        <h3 className=" max-w-xl sm:text-3xl text-lg text-center text-gray-700">
          {t.subheading.firstSentence}{' '}
          <span className="font-bold leading-snug">
            {t.subheading.secondSentence}
          </span>{' '}
          {t.subheading.thirdSentence}
        </h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 px-4">
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={index} {...testimonial} index={index} />
        ))}
      </div>
    </div>
  );
}
