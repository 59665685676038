import { createTranslations } from '../../utils/i18n';

interface Fields {
  title: string;
  upperHeading: string;
  cta: string;
}

export const adstartMessagesKnowMoreTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        title: 'Start your free trial today.',
        upperHeading: 'Ready to start?',
        cta: 'Free trial',
      },
      pt: {
        title: 'Comece seu teste grátis hoje.',
        upperHeading: 'Pronto para começar?',
        cta: 'Teste grátis',
      },
    },
  });
