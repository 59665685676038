import { createTranslations } from '../../../utils/i18n';

interface Fields {
  upperHeading: string;
  title: string;
  subheading: {
    firstSentence: string;
    secondSentence: string;
    thirdSentence: string;
  };
  firstPersonOcupation: string;
  secondPersonOcupation: string;
  firstPersonTestemonial: string;
  secondPersonTestemonial: string;
}

export const translations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        upperHeading: 'People love us',
        title: 'You are in great company',
        subheading: {
          firstSentence: 'See why',
          secondSentence: 'so many e-com and agency owners',
          thirdSentence: 'are using our software',
        },
        firstPersonOcupation: 'CEO',
        firstPersonTestemonial:
          'Before Adstart, our traffic managers were only able to work with 12 e-coms at a time, now they work with 18',
        secondPersonOcupation: 'E-commerce manager',
        secondPersonTestemonial:
          'Adstart was truly a game changer for us, our productivity and conversion increased a lot.',
      },
      pt: {
        upperHeading: 'As pessoas amam o Adstart',
        title: 'Você está em boa companhia',
        subheading: {
          firstSentence: 'Veja porquê',
          secondSentence: 'tantos profissionais de marketing',
          thirdSentence: 'estão usando nossa ferramenta',
        },
        firstPersonOcupation: 'CEO',
        firstPersonTestemonial:
          'Antes da Adstart, nossos gestores de tráfego conseguiam atender apenas 12 e-commerces, hoje eles conseguem atender 18.',
        secondPersonOcupation: 'E-commerce manager',
        secondPersonTestemonial:
          'Com a Adstart nós conseguimos ter uma visão clara do resultado de performance da Yeesco em segundos.',
      },
    },
  });
