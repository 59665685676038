import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/shadcn-ui/components/ui/accordion';

interface FAQSectionProps {
  faqItems: {
    question: string;
    answer: string;
  }[];
  header: string;
}

export function FAQSection({ faqItems, header }: FAQSectionProps) {
  return (
    <div className="max-w-7xl py-12 px-4 w-full font-poppins">
      <h1 className="text-4xl font-bold text-center mb-8 text-gray-700">
        {header}
      </h1>

      <Accordion type="single" collapsible className="max-w-7xl mx-auto">
        {faqItems.map((item, index) => (
          <AccordionItem key={`item-${index + 1}`} value={`item-${index + 1}`}>
            <AccordionTrigger className="text-start">
              {item.question}
            </AccordionTrigger>
            <AccordionContent>{item.answer}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
}
