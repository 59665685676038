import { AutoPlayCarousel } from '@/shadcn-ui/components/ui/auto-play-carousel';
import { LucideProps } from 'lucide-react';
import { ForwardRefExoticComponent, RefAttributes } from 'react';
import { UpperHeadingAndTitle } from '../UpperHeadingAndTitle';

interface BenefitsProps {
  title: string;
  upperHeading: string;
  benefitsValues: {
    title: string;
    description: string;
    icon: ForwardRefExoticComponent<
      Omit<LucideProps, 'ref'> & RefAttributes<SVGSVGElement>
    >;
  }[];
}

export function Benefits({
  title,
  upperHeading,
  benefitsValues,
}: BenefitsProps) {
  const row = benefitsValues.slice(0, benefitsValues.length);
  return (
    <div className="flex flex-col gap-8 w-full items-center max-w-7xl py-12 font-poppins">
      <UpperHeadingAndTitle upperHeading={upperHeading} title={title} />

      <div className="w-full max-w-7xl flex md:hidden">
        <AutoPlayCarousel className="[--duration:30s] ">
          {row.map((values, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center gap-4 mx-6 md:mx-0"
            >
              <div className="flex items-center justify-center rounded-full bg-purple-100 p-4">
                <values.icon className="w-8 h-8 text-primary stroke-[2.5px]" />
              </div>
              <div className="flex flex-col items-center justify-center">
                <p className="text-lg sm:text-xl font-semibold">
                  {values.title}
                </p>
                <p className="truncate text-sm text-slate-500">
                  {values.description}
                </p>
              </div>
            </div>
          ))}
        </AutoPlayCarousel>
      </div>
      <div className="w-full max-w-7xl hidden md:grid md:grid-cols-5 md:gap-4">
        {row.map((values, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center gap-4 mx-6 md:mx-0"
          >
            <div className="flex items-center justify-center rounded-full bg-purple-100 p-4">
              <values.icon className="w-8 h-8 text-primary stroke-[2.5px]" />
            </div>
            <div className="flex flex-col items-center justify-center">
              <p className="text-lg sm:text-xl font-semibold">{values.title}</p>
              <p className="truncate text-sm text-slate-500">
                {values.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
